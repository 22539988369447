/* login css */
body{
  font-family: 'Lato', sans-serif;
} 
.closeButtonContainer {
  text-align: end;
}
.loginHeaderContainer {
  text-align: center;
}
.logintext {
  font-size: 35px;
  font-weight: 900 !important;
  font-family: 'Lato', sans-serif;
}
.emailContainer {
  margin-top: 5% !important;
  margin-right: 10% !important;
  margin-left: 10% !important;
}
.emailTextField {
  background-color: white !important;
}
.passwordContainer {
  margin-top: 5% !important;
  margin-right: 10% !important;
  margin-left: 10% !important;
}
.passwordTextField {
  background-color: white !important;
}
.forgotPasswordContainer {
  text-align: end;
  padding: 2% 10% 0% 0%;
}
.forgotPasswordText {
  font-size: 13px !important;
  font-weight: bold !important;
}
.enterButtonContainer {
  text-align: center !important;
}
.enterButton {
  width: 30% !important;
  background-color: black !important;
  color: white !important;
  padding: 2% 0% 2% 0% !important;
  border-radius: 0px !important;
}
.registerButtonContainer {
  text-align: center;
  margin-top: 2% !important;
  margin-bottom: 7% !important;
}
.registerButton {
  width: 30% !important;
  background-color: black !important;
  color: white !important;
  padding: 2% 0% 2% 0% !important;
  border-radius: 0px !important;
}
.horizontalLineContainerLeft {
  text-align: center;
  margin-top: 2% !important;
  padding-left: 10% !important;
}
.horizontalLineContainerRight {
  text-align: center;
  margin-top: 2% !important;
  padding-right: 10% !important;
}
.registerButtonContainer {
  text-align: center;
}
.orText {
  margin-top: 2% !important;
  text-align: center;
}
.forgotPasswordAnchor{
  text-decoration: none;
  color: black;
}
/* register css */

.registerHeaderContainer {
  text-align: center;
}
.registertext {
  font-size: 35px;
  font-weight: bold;
}
.emailRegisterContainer {
  margin-top: 5% !important;
  margin-right: 10% !important;
  margin-left: 10% !important;
}
.emailRegisterTextField {
  background-color: white !important;
}
.sponsorContainer {
  margin-top: 5% !important;
  margin-right: 10% !important;
  margin-left: 10% !important;
}
.sponsorTextField {
  background-color: white !important;
}
.passwordContainer {
  margin-top: 5% !important;
  margin-right: 10% !important;
  margin-left: 10% !important;
}
.passwordTextField {
  background-color: white !important;
}
.confirmPasswordContainer {
  margin-top: 5% !important;
  margin-right: 10% !important;
  margin-left: 10% !important;
}
.confirmPasswordTextField {
  background-color: white !important;
}
.enterRegisterButton {
  width: 30% !important;
  background-color: black !important;
  color: white !important;
  padding: 2% 0% 2% 0% !important;
  border-radius: 0px !important;
}
.enterRegisterButtonContainer {
  text-align: center;
  margin-top: 5% !important;
  margin-bottom: 7% !important;
}

/* forgot password */
.passwordResetHeaderContainer {
  text-align: center;
}
.passwordResetHeader {
  font-size: 40px;
  font-weight: 900;
}
.passwordResetContainer {
  text-align: center;
  margin-top: 3% !important;
}
.passwordResetText {
  text-align: center;
  font-size: 17px;
  font-weight:bold;
}
.sendButton {
  width: 30% !important;
  background-color: black !important;
  color: white !important;
  padding: 2% 0% 2% 0% !important;
  border-radius: 0px !important;
}
.sendButtonContainer {
  text-align: center;
  margin-top: 7% !important;
  margin-bottom: 7% !important;
}
.emailResetContainer {
  margin-top: 8% !important;
  margin-right: 20% !important;
  margin-left: 20% !important;
}
.emailResetTextField {
  background-color: white !important;
}
.ok-container{
 padding-top: 5% !important;
 text-align: end; 
}
.ok-button{
  background-color: black !important;
  color: white !important;
  border-radius: 0px !important;
  padding-right: 8% !important;
  padding-left: 8% !important;
}

.disable-button{
  background-color: #888 !important;
  color: white !important;
  padding-right: 8% !important;
  padding-left: 8% !important;
  border-radius: 0px !important;
}
.cancel-container{
  padding-top: 5% !important;
  padding-left: 5% !important; 
 }
 .cancel-button{
   background-color: black !important;
   color: white !important;
   padding-right: 8% !important;
   padding-left: 8% !important;
   border-radius: 0px !important;
 }
 .button-cont{
   margin-bottom: 5% !important;
 }
 .available-playlist-cont-text{
  font-size: 35px;
  font-weight: 900 !important;
  padding-left: 10% !important;
  font-family: 'Lato', sans-serif;
 }
 .available-cont{
   padding-left: 5% !important;
 }
 .spantext{
  font-size: 19px;
  font-weight:400 !important;
  font-family: 'Lato', sans-serif;
  padding-left: 10% !important;
 }
 .paper{
   height:50vh;
   padding: 5% 5% 5% 5% !important;
 }
 .playlist-cont{
   border: black;
   border-style: solid;
 }