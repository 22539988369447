.view-play-container{
  height: 10vh;
  width: 100%;
}
.view-play-bottom-container{
  height: 10vh;
  border: white;
}
.add-delete-container{
  padding-top: 2% !important;
  margin-left: 1% !important;
  margin-bottom: 1% !important;
}
.add-icon-container{
  text-align: center;
  border-style: solid;
  border-right: white;
  height: 35px !important;
}
.sub-icon-container{
  text-align: center;
  border-style: solid;
  border-right: white;
  height: 35px !important;
}
.icon-container{
  text-align: center;
  border-style: solid;
  height: 35px !important;
  
}
.add-icon{
  font-size: 25px;
  font-weight: bold;
}
.sub-icon{
  font-size: 25px;
  font-weight: bold;
  padding-top: 15% !important;

}
.square-icon{
  background-color: black;
  height: 50%;
  width: 30%;
  margin: 21% 0% 0% 35%;
}
.collection-title-cont{
  background-color: 	#ededed;
}
.scroll{
  overflow-y: scroll !important;
  height: 90vh;
}
.playlist-cont{
  overflow-y: scroll;

}
.collection-title-text-cont{
  text-align: center;
  padding-left: 13%;
  padding-top: 4%;
  padding-bottom: 4%;
}
.collection-title-text{
  font-size: 20px;
  font-weight: 900;
}
.other-info-title-text-cont{
  text-align: center;
  padding-left: 5%;
  padding-top: 4%;
  padding-bottom: 4%;
}
.other-info-title-text{
  font-size: 20px;
  font-weight: 900;
}
body{
  font-family: 'Lato', sans-serif;
}
.arrow-cont{
  text-align: center;
  padding-top: 3%;
  padding-left: 8%;
}
.arrow-div{
  height: 45%;
  width: 40%;
  border-style: solid;
  text-align: center;
  padding-top: 2%;
}
/* .playlist-detail-container{
  padding-top: 10px;
} */
.other-info-span{
  text-align: center !important;
  padding-bottom: 3% !important;
  padding-top: 3% !important;
  font-size: 19px !important;
  font-weight: 900 !important;
}
.collection-title-header{
  background-color:	#ededed;
}
.arrow-up-button{
  border: black !important;
  border-width: 2px !important;
  border-style: solid !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.arrow-up-span{
  text-align: center !important;
}
.mood-cell{
  font-size: 13px !important;
  font-weight: bold !important;
}
.table-num{
  font-size: 15px !important;
  font-weight: 700 !important;
}
.table-text{
  font-weight: 700 !important;
}
.table-cell-playlist{
  padding-top:0px !important;
  padding-bottom: 0px !important;
  background: #ffffff !important;

}

.selected-table-cell-playlist{
  padding-top:0px !important;
  padding-bottom: 0px !important;
  background: #f2f2f2 !important;

}

.sub-icon-button{
  padding: 0px !important;
  color: black !important;
  text-align: center;
}