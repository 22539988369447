.edit-title{
  background-color: #F49739 ;
  padding: 20px;
  text-align: center;
  font-weight: 700;
}

.edit-title-fields{
  background-color: #f2f2f2;
  padding: 10px !important;
}

.change-playlist-btn{
  background-color: black !important;
  color: white !important;
  padding: 12px !important;
  border-radius: 0px !important;
  position: relative;
  top: -2px;
}

.delete-button{
  background-color: black !important;
  color: white !important;
  padding-right: 4% !important;
  padding-left: 4% !important;
}

.change-playlist-name{
  background-color: white !important;
  padding: 7px !important;
  margin-left: 20px !important;
  padding-left: 10px !important;
  width: 50% !important;
}

.btn-up-down{
  background-color: #E2E2E2 !important;
  border-radius: 0px !important;
  border: 1px solid #2e2e2e !important;
  margin: 5px !important;
  padding: 5px !important;
  min-width: 42px !important;
}

.update-btn{
  background-color: #f6842d !important;
  text-align: center !important;
  border-radius: 0% !important;
}

/* .btn-container{
  width: 75%;
} */
.btn-container-mob{
  position: fixed;
  width: 100%;
}
.song-playlist-cont{
 margin-bottom: 8px !important; 
 overflow-y: scroll !important; 
 height: 69vh;

}