.login-button{
  background-color: #1C1C1C !important;
  color: white !important;
  min-width: 150px !important;
  border-radius: 0px !important;
  /* F49045 */
}

.login-background{
  background: url("./images/login/bg.jpg");
  background-size: cover;
  height: 100vh;
  /* overflow-x: hidden !important; */
  /* overflow-y: hidden !important; */
}

.login-btn-container{
  text-align: center;
  position: relative;
  bottom: 150px;
}

.MuiBackdrop-root{
  background-color: rgba(0,0,0,0.3) !important;
}